import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Card,
  Button,
  CardHeader,
  Container,
  TextField,
  Divider,
  CardContent,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from '@material-ui/core';
import { Edit, Trash2 } from 'react-feather';
import Swal from 'sweetalert2';
import Snackbar from '@material-ui/core/Snackbar';
import { makeRequest } from '../api/Api';

const Register = () => {
  const navigate = useNavigate();
  const [btn, setBtn] = useState(1);// btn status, 1 untuk tambah, 2 utk edit
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [pegawai, setPegawai] = useState([{ id: 0, nama_pegawai: '--Pilih Pegawai--' }]);
  const [inputUser, setUser] = useState({
    username: '',
    password: '',
    level: 2,
    id_pegawai: 0
  });
  const [disable, setDisable] = useState(false);
  const [userId, setId] = useState(0);
  const [pengguna, setPengguna] = useState([]);
  const [reload, setReload] = useState(0);

  const loadPegawai = () => {
    makeRequest({
      url: 'unregistered',
      successCallback: (data) => {
        const gabung = pegawai.concat(data.pegawai);
        setPegawai(gabung);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const loadUser = () => {
    makeRequest({
      url: 'user',
      successCallback: (data) => {
        setPengguna(data.pengguna);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  const handleChange = (event) => {
    setUser({
      ...inputUser,
      [event.target.name]: event.target.value
    });
  };

  const addUser = () => {
    makeRequest({
      url: 'user',
      values: inputUser,
      successCallback: (data) => {
        console.log(data);
        setUser({
          username: '',
          password: '',
          level: 2,
          id_pegawai: 0
        });
        setPegawai([{ id: 0, nama_pegawai: '--Pilih Pegawai--' }]);
        setReload(reload + 1);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const editUser = () => {
    const values = { ...inputUser, id: userId };
    makeRequest({
      url: 'update_user',
      values,
      successCallback: (data) => {
        setMsg(data.msg);
        setUser({
          username: '',
          password: '',
          level: 2,
          id_pegawai: 0
        });
        setBtn(1);
        setOpen(true);
        setDisable(false);
        setPegawai([{ id: 0, nama_pegawai: '--Pilih Pegawai--' }]);
        setReload(reload + 1);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const hapusUser = (idUser) => {
    Swal.fire({
      title: 'Yakin Hapus Data Ini?',
      text: 'Data Akan Terhapus Dari Sistem',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus Data!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        makeRequest({
          url: `user/${idUser}`,
          successCallback: (data) => {
            setPegawai([{ id: 0, nama_pegawai: '--Pilih Pegawai--' }]);
            setMsg(data.msg);
            setOpen(true);
            setReload(reload + 1);
          },
          failureCallback: (err) => {
            console.log(err);
          },
          requestType: 'DELETE',
        });
      }
    });
  };

  const handleEdit = (idUser, uname, lvl, pwd, idPeg) => {
    setUser({
      username: uname,
      password: pwd,
      level: lvl,
      id_pegawai: idPeg,
    });
    setId(idUser);
    setDisable(true);
    setBtn(2);
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
      Ok
    </Button>
  );

  useEffect(() => {
    loadPegawai();
    loadUser();
  }, [reload]);

  return (
    <>
      <Helmet>
        <title>Pengguna Aplikasi</title>
      </Helmet>
      <Box sx={{
        m: 3
      }}
      >
        {
          open ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={open}
              autoHideDuration={2000}
              message={msg}
              action={action}
            />
          ) : (
            null
          )
        }
        <Card>
          <CardHeader title="Daftar Pengguna" />
          <Divider />
          <CardContent>
            <Grid 
              container 
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} sm={4}>
                <TextField 
                  margin="dense"
                  label="Username"
                  type="text"
                  name="username"
                  onChange={handleChange}
                  value={inputUser.username}
                  fullWidth
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField 
                  margin="dense"
                  label="Password"
                  type="password"
                  name="password"
                  onChange={handleChange}
                  value={inputUser.password}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField 
                  margin="dense"
                  label="Pegawai"
                  name="id_pegawai"
                  onChange={handleChange}
                  fullWidth
                  required
                  select
                  SelectProps={{ native: true }}
                  value={inputUser.id_pegawai}
                  variant="outlined"
                  disabled={disable}
                >
                  {
                    pegawai.map((data) => (
                      <option 
                        key={data.id}
                        value={data.id}
                      >
                        {data.nama_pegawai}
                      </option>
                    ))
                  }
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                {
                  btn === 1 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={addUser}
                    >
                      Tambah Pengguna
                    </Button>
                  ) : (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={editUser}
                    >
                      Edit Pengguna
                    </Button>
                  )
                }
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ ml: 3, mr: 3 }}>
        <Card>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    No
                  </TableCell>
                  <TableCell>
                    Username
                  </TableCell>
                  <TableCell colSpan="2">
                    Password
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  pengguna.map((user, idx) => (
                    <TableRow key={user.id}>
                      <TableCell> 
                        {idx + 1} 
                      </TableCell>
                      <TableCell> 
                        {user.username} 
                      </TableCell>
                      <TableCell>
                        ******************************
                      </TableCell>
                      <TableCell>
                        <IconButton sx={{ color: '#ff6f00' }} onClick={() => { handleEdit(user.id, user.username, user.level, user.password, user.id_pegawai); }}>
                          <Edit />
                        </IconButton>
                        {
                          user.level === '2' ? (
                            <IconButton sx={{ color: '#f44336' }} onClick={() => { hapusUser(user.id); }}>
                              <Trash2 />
                            </IconButton>
                          ) : (
                            null
                          )
                        }
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Register;
