/* eslint-disable */
import {saveJWT, saveUser, saveIdPeg} from './LocalStorage';

const successfulAuthenticationCallback = (data) => {
    const {user_level, token, id_peg} = data;
    saveUser(user_level);
    saveJWT(token);
    saveIdPeg(id_peg);
};

export default successfulAuthenticationCallback;