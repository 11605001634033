import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  BookOpen as Absen,
  Briefcase as Pekerjaan,
  Award as Jabatan,
  Monitor as Perilaku,
  BarChart2 as Skp,
  Settings as Pengaturan,
} from 'react-feather';
import NavItem from './NavItem';
import { makeRequest } from '../api/Api';
import { loadIdPegawai } from '../api/LocalStorage';

const urlFoto = 'https://www.skp.kunyit.tanahlautkab.go.id/backend/upload/pegawai/';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Pegawai'
  },
  {
    href: '/app/absen',
    icon: Absen,
    title: 'Absen'
  },
  {
    href: '/app/skp',
    icon: Skp,
    title: 'SKP'
  },
  {
    href: '/app/jabatan',
    icon: Jabatan,
    title: 'Master Jabatan'
  },
  {
    href: '/app/pekerjaan',
    icon: Pekerjaan,
    title: 'Master Pekerjaan'
  },
  {
    href: '/app/perilaku',
    icon: Perilaku,
    title: 'Master Perilaku Kerja'
  },
  {
    href: '/app/register',
    icon: UserPlusIcon,
    title: 'Pengguna'
  },
  {
    href: '/app/potongan',
    icon: Pengaturan,
    title: 'Pengaturan Potongan'
  },
];

const itemPeg = [
  {
    href: '/app/lap_harian',
    icon: Skp,
    title: 'Laporan Harian'
  }
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const idPeg = loadIdPegawai();
  const [user, setUser] = useState({
    avatar: '/static/images/avatars/tala.jpg',
    jobTitle: 'Administrator',
    name: 'Administrator'
  });

  const loadPegawai = () => {
    makeRequest({
      url: `dtl_pegawai/${idPeg}`,
      successCallback: (data) => {
        setUser({
          avatar: urlFoto + data.pegawai.foto,
          jobTitle: data.pegawai.nama_jabatan,
          name: data.pegawai.nama
        });
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (idPeg !== '0') {
      loadPegawai();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Avatar
          component={RouterLink}
          src={user.avatar}
          sx={{
            cursor: 'pointer',
            width: 64,
            height: 64
          }}
          to="/app/dashboard"
        />
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        {
          idPeg !== '0' ? (
            <List>
              {itemPeg.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
            </List>
          ) : (
            <List>
              {items.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
            </List>
          )
        }
        
      </Box>
      <Box sx={{ flexGrow: 1 }} />

    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {
  },
  openMobile: false
};

export default DashboardSidebar;
