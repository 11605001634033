import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import MasterJabatan from '../components/master/MasterJabatan';
import { makeRequest } from '../api/Api';

const Master = () => {
  const [jabatan, setJabatan] = useState([]);
  const [reload, setReload] = useState(0);

  const handleReload = (val) => {
    setReload(val);
  };

  const loadJabatan = () => {
    makeRequest({
      url: 'jabatan',
      successCallback: (data) => {
        if (data.status === 1) {
          setJabatan(data.jabatan);
        }
      },
      failureCallback: (error) => {
        console.log(error);
      },
      requestType: 'GET'

    });
  };

  useEffect(() => {
    loadJabatan();
  }, [reload]);
 
  return (
    <>
      <Helmet>
        <title>Data Master</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <MasterJabatan jabatan={jabatan} callbackReload={handleReload} reload={reload} />
        </Container>
      </Box>
    </>
  );
};

export default Master;
