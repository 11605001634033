import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Alert
} from '@material-ui/core';

import { makeRequest } from '../api/Api';
import successfulAuthenticationCallback from '../api/Auth';

const Login = () => {
  const navigate = useNavigate();
  const [message, setMsg] = useState('');
  const [failed, setFailed] = useState(false);

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          { failed === true ? <Alert severity="error">{message}</Alert> : null } 
          
          <Formik
            enableReinitialize
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required('Password Harus Diisi')
            })}
            onSubmit={(values, { resetForm }) => {
              makeRequest({
                url: 'cek_login',
                values,
                successCallback: (data) => {
                  if (data.status === 1) {
                    setFailed(true);
                    setMsg(data.msg);
                    resetForm();
                  } else {
                    successfulAuthenticationCallback(data);
                    navigate('/app/dashboard', { replace: true });
                  }
                },
                failureCallback: (error) => {
                  console.log(error);
                }
              });
              // navigate('/app/dashboard', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    <center>
                      Selamat Datang    
                    </center>
                  </Typography>
                  <Typography
                    variant="h5"
                  >
                    <center>
                      Silakan Masukkan Username & Password Anda
                    </center>
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
