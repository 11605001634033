import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import { loadIdPegawai } from '../api/LocalStorage';
import RekapAbsen from '../components/dashboard/RekapAbsen';
import Welcome from '../components/dashboard/Welcome';

const Dashboard = () => {
  const idPeg = loadIdPegawai();
  return (
  <>
    <Helmet>
      <title>Dashboard</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          {
            idPeg === '0' ? (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <RekapAbsen />
              </Grid>
            ) : (
              <Grid
                item
                lg={12}
                md={12}
                xl={12}
                xs={12}
              >
                <Welcome />
              </Grid>
            )
          }
        
        </Grid>
      </Container>
    </Box>
  </>
  );
};

export default Dashboard;
