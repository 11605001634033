import {
  Card, CardHeader, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, TablePagination, TextField, CardContent, Grid, Divider
} from '@material-ui/core';
import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Snackbar from '@material-ui/core/Snackbar';
import Swal from 'sweetalert2';
import NumberFormat from 'react-number-format';
import { Edit, Trash2 } from 'react-feather';
import { makeRequest } from '../../api/Api';

const MasterJabatan = ({ jabatan, callbackReload, reload }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [editedId, setId] = useState(null);
  const [nmJabatan, setNmJabatan] = useState(''); 
  const [jmlTunjangan, setTunjangan] = useState(0); 
  const [btn, setBtn] = useState(1);// btn status, 1 untuk tambah, 2 utk edit
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  
  const click = reload;

  const changeJabatan = (evt) => {
    setNmJabatan(evt.target.value);
    evt.preventDefault();
  };

  const changeTunjangan = (evt) => {
    setTunjangan(evt.target.value);
    evt.preventDefault();
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleEditClicked = (id, nama, jml) => {
    setId(id);
    setNmJabatan(nama);
    setTunjangan(jml);
    setBtn(2);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const addJabatan = () => {
    const values = { nama_jabatan: nmJabatan, tunjangan: jmlTunjangan };
    
    makeRequest({
      url: 'jabatan',
      values,
      successCallback: (data) => {
        callbackReload(click + 1);
        setNmJabatan('');
        setTunjangan('');
        setMsg(data.msg);
        setOpen(true);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const editJabatan = () => {
    const values = { nama_jabatan: nmJabatan, id: editedId, tunjangan: jmlTunjangan };
    makeRequest({
      url: 'jabatan',
      values,
      successCallback: (data) => {
        callbackReload(click + 1);
        setNmJabatan('');
        setTunjangan(0);
        setBtn(1);
        setMsg(data.msg);
        setOpen(true);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'POST'
    });
  };

  const hapus = (idJabatan) => {
    Swal.fire({
      title: 'Yakin Hapus Data Ini?',
      text: 'Data Akan Terhapus Dari Sistem',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus Data!',
      cancelButtonText: 'Batal'
    }).then((result) => {
      if (result.isConfirmed) {
        makeRequest({
          url: `jabatan/${idJabatan}`,
          successCallback: (data) => {
            // console.log(data);
            // window.location.reload();
            callbackReload(click + 1);
            setMsg(data.msg);
            setOpen(true);
          },
          failureCallback: (err) => {
            console.log(err);
          },
          requestType: 'DELETE',
        });
      }
    });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
      Ok
    </Button>
  );

  return (
    <>
      <Box sx={{ mb: 3 }}>
        {
          open ? (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={open}
              autoHideDuration={2000}
              message={msg}
              action={action}
            />
          ) : (
            null
          )
        }
      
          <Card>
            <CardHeader title="Tambah Data Jabatan" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                <TextField 
                  margin="dense"
                  label="Nama Jabatan"
                  type="text"
                  fullWidth
                  autoFocus
                  value={nmJabatan}
                  onChange={changeJabatan}
                />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField 
                    margin="dense"
                    label="Jumlah Tunjangan"
                    type="number"
                    fullWidth
                    value={jmlTunjangan}
                    onChange={changeTunjangan}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  {
                    btn === 1 ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={addJabatan}
                        sx={{ mt: 2 }}
                      >
                      Tambah Jabatan
                      </Button>
                    ) : (
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={editJabatan}
                        sx={{ mt: 2 }}
                      >
                      Edit Jabatan
                      </Button>
                    )
                  }
                
                </Grid>
              </Grid>
            </CardContent>
          </Card>
      </Box>
      <Card>
        <CardHeader 
          title="Data Master Jabatan"
        />
        <Divider />
            <PerfectScrollbar>
                <Box sx={{ minWidth: 1050 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    No
                                </TableCell>
                                <TableCell>
                                    Nama Jabatan
                                </TableCell>
                                <TableCell colSpan="2">
                                    Tunjangan
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { jabatan.length === 0 ? (
                                <TableRow>
                                  <TableCell colSpan="2">
                                    Loading ......
                                  </TableCell>
                                </TableRow>
                            ) : (
                              jabatan.slice(page * limit, page * limit + limit).map((jab, index) => {
                                const no = index + 1; 
                                return (
                                <TableRow key={jab.id}>
                                  <TableCell>
                                    {no}
                                  </TableCell>
                                  <TableCell>
                                    {jab.nama_jabatan}
                                  </TableCell>
                                  <TableCell>
                                    {/* {`Rp. ${jab.tunjangan}`} */}
                                    <NumberFormat 
                                      value={jab.tunjangan}
                                      displayType="text"
                                      thousandSeparator
                                      prefix="RP."
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <IconButton sx={{ color: '#ff6f00' }} onClick={() => { handleEditClicked(jab.id, jab.nama_jabatan, jab.tunjangan); }}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton sx={{ color: '#f44336' }} onClick={() => { hapus(jab.id); }}>
                                        <Trash2 />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                                );
                              })
                                
                            ) }
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
              component="div"
              count={jabatan.length}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 30]}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
            />
      </Card>
    </>
  );
};
export default MasterJabatan;
