import { useState, useEffect } from 'react';
import {
  Avatar, Card, CardHeader, Box, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, TablePagination, TextField, CardContent, Grid, Divider, Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Snackbar from '@material-ui/core/Snackbar';
import { useNavigate } from 'react-router-dom';
import { FolderPlus, Search } from 'react-feather';
import { makeRequest } from '../../api/Api';
import getInitials from '../../utils/getInitials';
import { savePegAbsen } from '../../api/LocalStorage';

const AbsenListResult = () => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [absen, setAbsen] = useState([]);

  const navigate = useNavigate();

  const urlFoto = 'https://skpkunyit.desa-ku.id/backend/upload/pegawai/';

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const inputAbsen = (pegawai) => {
    const dtPegawai = { id: pegawai.id, nama: pegawai.nama, no_absen: pegawai.no_absen };
    savePegAbsen(JSON.stringify(dtPegawai));
    navigate('/app/input_absen', { replace: false });
  };

  const cari = (pegawai) => {
    const dtPegawai = { id: pegawai.id, nama: pegawai.nama, no_absen: pegawai.no_absen };
    savePegAbsen(JSON.stringify(dtPegawai));
    navigate('/app/kehadiran', { replace: false });
  };

  const loadAbsen = () => {
    makeRequest({
      url: 'absen',
      successCallback: (data) => {
        // console.log(data.absen);
        setAbsen(data.absen);
      },
      failureCallback: (err) => {
        console.log(err);
      },
      requestType: 'GET'
    });
  };
  useEffect(() => {
    loadAbsen();
  }, []);
  return (
    <>
      <Card>
          <CardHeader title="Absensi Pegawai" />
          <PerfectScrollbar>
              <Box sx={{ minWidth: 1050 }}>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell>
                                  No
                              </TableCell>
                              <TableCell>
                                  Nama Pegawai
                              </TableCell>
                              <TableCell>
                                  Proses
                              </TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {
                              absen.slice(0, limit).map((abs, idx) => (
                                  <TableRow key={abs.id}>
                                    <TableCell>{idx + 1}</TableCell>
                                    <TableCell>
                                      <Box
                                        sx={{
                                          alignItems: 'center',
                                          display: 'flex'
                                        }}
                                      >
                                        <Avatar src={urlFoto + abs.foto} sx={{ mr: 2 }}>
                                          {getInitials(abs.nama)}
                                        </Avatar>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {abs.nama}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton sx={{ color: '#ff6f00' }} title="Input Absen Manual" onClick={() => { inputAbsen(abs); }}>
                                            <FolderPlus />
                                        </IconButton>
                                        <IconButton sx={{ color: '#04AA6D' }} title="Lihat Absensi" onClick={() => { cari(abs); }}>
                                            <Search />
                                        </IconButton>
                                    </TableCell>
                                  </TableRow>
                              ))
                          }
                      </TableBody>
                  </Table>
              </Box>
          </PerfectScrollbar>
          <TablePagination 
            component="div"
            count={absen.length}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[10, 20, 30]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
          />
      </Card>
    </>
  );
};
export default AbsenListResult;
