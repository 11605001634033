import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { 
  Card,
  CardContent,
  CardHeader,
  Button,
  Grid
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeRequest, uploadFile } from '../../api/Api';

const ImportAbsen = () => {
  const [fileInfo, setFile] = useState({
    currentFile: undefined,
    progress: 0,
    message: '',
  });
  const [btn, setBtn] = useState(true);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');

  const pilihFile = (event) => {
    setFile({
      currentFile: event.target.files[0],
      progress: 0,
      message: ''
    });
    setBtn(false);
  };

  const upload = () => {
    setBtn(true);
    uploadFile({
      url: 'import_absen',
      file: fileInfo.currentFile,
      progressCallback: (dataProgress) => {
        console.log(dataProgress);
      },
      successCallback: (data) => {
        console.log(data);
        setFile({
          currentFile: undefined,
          progress: 0,
          message: '',
        });
        setBtn(true);
        setOpen(true);
        setMsg(data.msg);
      },
      failureCallback: (err) => {
        console.log(err);
      }
    });
  };

  const action = (
    <Button sx={{ backgroundColor: '#FFFFFF' }} size="small" onClick={() => setOpen(false)}>
        Ok
    </Button>
  );

  return (
   <>
   {
    open ? (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={2000}
        message={msg}
        action={action}
      />
    ) : (
      null
    )
  }
    <Card sx={{ mb: 3 }}>
     <CardHeader title="Import Data Absen" />
      <CardContent>
       <Grid container spacing={3}>
         <Grid item xs={4} md={4}>
           <Button
             color="warning"
             fullWidth
             variant="contained"
             component="label"
           >
             Pilih File
             <input type="file" hidden onChange={pilihFile} />
           </Button>
         </Grid>
         <Grid item xs={4} md={4}>
         {
          fileInfo.currentFile === undefined ? (
            'Belum Ada File Dipilih'
          ) : (
            fileInfo.currentFile.name
          )
          
         }
         </Grid>
         <Grid item xs={4} md={4}>
           <Button
             color="success"
             fullWidth
             variant="contained"
             disabled={btn}
             onClick={upload}
           >
             Upload
           </Button>
         </Grid>
       </Grid>
       
      </CardContent>
    </Card>
   </>
  );
};

export default ImportAbsen;
