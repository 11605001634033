/* eslint-disable */
import axios from 'axios';

const API = axios.create({
    baseURL: 'https://www.skp.kunyit.tanahlautkab.go.id/backend',
    responseType: 'json',
});

const getRequestConfiguration = (authorization) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    if (authorization) headers.Authorization = `Bearer ${authorization}`;
    return { headers };
};

export const makeRequest = ({
    url,
    values,
    successCallback,
    failureCallback,
    requestType = 'POST',
    authorization = null,
}) => {

    const requestConfiguration = getRequestConfiguration(authorization);
    let promise;

    switch (requestType) {
        case 'GET':
            promise = API.get(url, requestConfiguration);
            break;
        case 'POST':
            promise = API.post(url, values, requestConfiguration);
            break;
        case 'DELETE':
            promise = API.delete(url, requestConfiguration);
            break;
        default:
            return true;
    }

    promise
        .then((response) => {
            const { data } = response;
            successCallback(data);
        })
        .catch((error) => {
            if (error.response) {
                failureCallback(error.response.data);
            }
        });
};

export const uploadFile = ({
    url,
    file,
    progressCallback,
    successCallback,
    failureCallback,
}) => {
    let progres = 0;
    const header = {
        'Content-Type': 'multipart/form-data',
         onUploadProgress: function(progressEvent) {
              const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
              progres = percentCompleted;
            }
    }
    let formData = new FormData();
    formData.append('file_kirim', file);
    let promise;

    promise = API.post(url, formData, header);

    promise
        .then((response) => {
            progressCallback(progres);
            const { data } = response;
            successCallback(data);
        })
        .catch((error) => {
            if (error.response) {
                failureCallback(error.response.data);
            }
        });

}