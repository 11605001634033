import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import CustomerListResults from '../components/customer/CustomerListResults';
import CustomerListToolbar from '../components/customer/CustomerListToolbar';
import AddPegawai from '../components/customer/AddPegawai';
import EditPegawai from '../components/customer/EditPegawai';
import customers from '../__mocks__/customers';

const CustomerList = ({ page }) => {
  const [dataPegawai, setPegawai] = useState([]);
  const halaman = page.hal;

  const dataPegawaiHook = (data) => {
    setPegawai(data);
  };

  const RenderPage = () => {
    if (halaman === 'daftar') {
      return (
        <>
          <CustomerListToolbar />
          <Box sx={{ pt: 3 }}>
            <CustomerListResults callBackData={dataPegawaiHook} />
          </Box>
        </>
      );
    } 
    if (halaman === 'add') {
      return (
        <AddPegawai />
      );
    }
    if (halaman === 'edit') {
      return (
        <EditPegawai pegawai={dataPegawai} />
      );
    }
    return null;
  };
  return (
    <>
      <Helmet>
        <title>Daftar Pegawai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <RenderPage />
        </Container>
      </Box>
    </>
  );
};

export default CustomerList;
